html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

#root, .App {
    width: 100%;
}

/*background-color: #282c34;*/
