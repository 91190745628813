.page-guide-moment {

  &-header {
    width: 100%;
  }

  &-tips {
    padding: 0 20px 20px 20px;

    &-item {
      margin-bottom: 12px;

      &-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 4px;
      }

      &-infos {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;

        &-item {
          display: flex;
          margin-bottom: 8px;
        }
      }
    }
  }
}
